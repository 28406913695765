import { useEffect, useMemo, useState } from "react";
import { Center, Flex } from "../../common/Styles";
import BouncingMouse from "./BouncingMouse";
import styled, { keyframes, useTheme } from "styled-components";
import { Icon } from "@iconify-icon/react";

const Title = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-size: clamp(1rem, 1vw + 0.5rem, 3rem);
`;

const Description = styled.span`
  color: ${(props) => props.theme.colors.light};
  font-weight: 300;
  font-size: clamp(2rem, 2vw + 1rem, 4rem);
  letter-spacing: 0.1em;
`;

const BlinkingCaretAnimation = keyframes`
    from, to {
        border-color: transparent;
    }
    50% {
        border-color: #4a7fe5;
    }
    `;
const TypingAnimation = keyframes`
    0% {
        width: 0;
    }
    70% {
        width: 100%;
    }
    90%, 100% {
        width: 0;
    }
    `;

const AnimatedDescription = styled(Description)`
  background-image: ${(props) => props.theme.colors.gradient};
  background-clip: text;
  color: transparent;
  display: inline-block;
  border-right: 3px solid ${(props) => props.theme.colors.blue};
  animation: ${TypingAnimation} 2s steps(8, end) infinite,
    ${BlinkingCaretAnimation} 0.5s step-end infinite;
  font-weight: 600;
  white-space: nowrap;
`;

const About = () => {
  const { colors } = useTheme();

  const descriptions = useMemo(
    () => ["A Developer.", "A Freelancer.", "A Gamer.", "A Car Enthusiast."],
    []
  );
  const [currentDescription, setCurrentDescription] = useState(descriptions[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDescription((prev) => {
        const index = descriptions.indexOf(prev);
        return descriptions[(index + 1) % descriptions.length];
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [descriptions]);

  return (
    <Center style={{ flexDirection: "column", height: "100vh", gap: "1em" }}>
      <Title>Hello, I am Angelo</Title>
      <div>
        <AnimatedDescription>{currentDescription}</AnimatedDescription>
      </div>
      <Flex
        style={{
          color: colors.ligthBlue,
          fontSize: "2.5rem",
          cursor: "pointer",
          gap: ".5em",
        }}
      >
        <Icon
          icon="ic:baseline-email"
          onClick={() => {
            window.open("mailto:danielangelonegri@gmail.com");
          }}
        />
        <Icon
          icon="mdi:github"
          onClick={() => {
            window.open(
              "https://github.com/angelo-negri",
              "_blank",
              "noreferrer"
            );
          }}
        />
        <Icon
          icon="ic:baseline-telegram"
          onClick={() => {
            window.open("https://telegram.me/aNNgeL0");
          }}
        />
      </Flex>
      <div style={{ marginTop: "2em" }}>
        <BouncingMouse />
      </div>
    </Center>
  );
};

export default About;
