import { Heading } from "../../common/Styles";
import { Code, Database, Globe, Layout, Palette, Server } from "lucide-react";
import Skill from "./Skill";

export type SkillCategoryType = "frontend" | "backend" | "others";
export type SkillType = {
  name: string;
  experience: number;
  icon: React.ComponentType;
};
const skills: Record<SkillCategoryType, SkillType[]> = {
  frontend: [
    { name: "HTML", experience: 9, icon: Globe },
    {
      name: "CSS",
      icon: Palette,
      experience: 4,
    },
    {
      name: "Tailwind CSS",
      icon: Palette,
      experience: 5,
    },
    {
      name: "Sass",
      icon: Palette,
      experience: 5,
    },
    {
      name: "JavaScript",
      icon: Code,
      experience: 5,
    },
    {
      name: "Typescript",
      icon: Code,
      experience: 4,
    },
    {
      name: "React",
      icon: Layout,
      experience: 5,
    },
    {
      name: "Next.js",
      icon: Layout,
      experience: 4,
    },
    {
      name: "Angular",
      icon: Layout,
      experience: 3,
    },
    {
      name: "Vercel",
      icon: Layout,
      experience: 5,
    },
    {
      name: "Storybook",
      icon: Layout,
      experience: 5,
    },
    {
      name: "Jest",
      icon: Layout,
      experience: 5,
    },
    {
      name: "Cypress",
      icon: Layout,
      experience: 5,
    },
    {
      name: "Playwright",
      icon: Layout,
      experience: 5,
    },
  ],
  backend: [
    {
      name: "Node.js",
      icon: Server,
      experience: 5,
    },
    {
      name: "Express",
      icon: Server,
      experience: 5,
    },
    {
      name: "Nest.js",
      icon: Server,
      experience: 4,
    },
    {
      name: "GraphQL",
      icon: Database,
      experience: 4,
    },
    {
      name: "REST",
      icon: Server,
      experience: 5,
    },
    {
      name: "Pupeeteer",
      icon: Server,
      experience: 3,
    },
    {
      name: "GraphQL",
      icon: Server,
      experience: 4,
    },
    {
      name: "MongoDB",
      icon: Database,
      experience: 4,
    },
    {
      name: "PostgreSQL",
      icon: Database,
      experience: 5,
    },
    {
      name: "MySQL",
      icon: Database,
      experience: 5,
    },
    {
      name: "Redis",
      icon: Database,
      experience: 5,
    },
    {
      name: "Docker",
      icon: Server,
      experience: 4,
    },
    {
      name: "Kubernetes",
      icon: Server,
      experience: 3,
    },
    {
      name: "TypeORM",
      icon: Database,
      experience: 5,
    },
    {
      name: "Firebase",
      icon: Server,
      experience: 5,
    },
    {
      name: "AWS",
      icon: Server,
      experience: 4,
    },
  ],
  others: [
    {
      name: "Git",
      icon: Server,
      experience: 5,
    },
    {
      name: "Solidity",
      icon: Server,
      experience: 3,
    },
    {
      name: "Agile",
      icon: Server,
      experience: 5,
    },
    {
      name: "Scrum",
      icon: Server,
      experience: 5,
    },
    {
      name: "Jira",
      icon: Server,
      experience: 5,
    },
    {
      name: "Mentoring",
      icon: Server,
      experience: 4,
    },
    {
      name: "Code Review",
      icon: Server,
      experience: 4,
    },
  ],
};

const Skills = () => (
  <>
    <Heading style={{ marginBottom: "2rem" }}>Skills</Heading>
    {Object.keys(skills).map((category) => (
      <Skill
        category={category as SkillCategoryType}
        skills={skills[category as SkillCategoryType]}
      />
    ))}
  </>
);

export default Skills;
