import styled from "styled-components";

const Navigation = styled.nav`
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 100%;
`;

const UnorderedListItemst = styled.ul`
  list-style: none;
  color: ${(props) => props.theme.colors.light};
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  gap: 20px;
`;

const ListItem = styled.li`
  cursor: pointer;
  position: relative;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
    &::before {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 10px;
      content: "";
      width: 5px;
      height: 5px;
      background-color: ${(props) => props.theme.colors.blue};
      animation: fadeIn 0.5s forwards;
    }
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Header = () => (
  <Navigation>
    <UnorderedListItemst>
      <ListItem>
        <Anchor href="/">About</Anchor>
      </ListItem>
      <ListItem>
        <Anchor href="/skills">Skills</Anchor>
      </ListItem>
      <ListItem>
        <Anchor href="/experience">Experience</Anchor>
      </ListItem>
      <ListItem>
        <Anchor href="/experiments">Experiments</Anchor>
      </ListItem>
      <ListItem>
        <Anchor href="/contact">Contact</Anchor>
      </ListItem>
    </UnorderedListItemst>
  </Navigation>
);

export default Header;
