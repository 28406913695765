import { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";

const Flex = styled.div`
  display: flex;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
`;

const ScrollContainer = styled.div`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  height: auto;
  overflow-y: scroll;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5rem;
`;

const Center = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  font-size: 2.55rem;
  line-height: 2.25rem;
  color: #fff;
  cursor: default;
`;

const Subheading = styled.h2`
  font-size: 1.55rem;
  line-height: 2rem;
  text-decoration: underline;
  color: #fff;
  cursor: default;
`;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "outline";
}
const Button = styled.button<ButtonProps>`
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: transparent;

  ${(props) =>
    props.variant === "outline" &&
    css`
      border: 1px solid #e5e7eb;
      color: #fff;

      &:hover {
        background-color: #e5e7eb;
        color: #000;
      }
    `}
`;

interface ProgressBarProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  value: number;
  width?: number;
  height?: number;
}
const ProgressBar = styled.div<ProgressBarProps>`
  -webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) 0/20% repeat-x;

  /* Dynamically set background with gradient using props */
  background: linear-gradient(
      90deg,
      #ffcc00 ${(props) => props.value * 20}%,
      #ddd 0
    )
    0/100% no-repeat;
  ${(props) =>
    css`
      width: ${props.width || 80}px;
      height: ${props.height || 10}px;
    `};
`;

export {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Layout,
  ScrollContainer,
  Subheading,
  ProgressBar,
};
