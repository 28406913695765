import { SkillType, SkillCategoryType } from ".";
import {
  Box,
  Button,
  Flex,
  ProgressBar,
  Subheading,
} from "../../common/Styles";

interface Props {
  category: SkillCategoryType;
  skills: SkillType[];
}
const Skill: React.FC<Props> = ({ category, skills }) => {
  return (
    <>
      <Subheading
        style={{
          marginTop: "1rem",
          marginBottom: "1rem",
          textTransform: "capitalize",
        }}
      >
        {category}
      </Subheading>
      <Flex style={{ gap: "1rem", width: "100%", flexWrap: "wrap" }}>
        {(skills || []).map((skill) => (
          <Box
            style={{
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Button
              key={skill.name}
              variant="outline"
              style={{ width: "150px" }}
            >
              {skill.name}
            </Button>
            <Box style={{ width: "100%", alignItems: "center" }}>
              <p style={{ color: "#fff", fontSize: "9px" }}>Experience</p>
              <ProgressBar value={skill.experience} />
            </Box>
          </Box>
        ))}
      </Flex>
    </>
  );
};

export default Skill;
