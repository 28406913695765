import styled, { keyframes } from "styled-components";

// Define the bounce animation
const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

// Create a styled mouse icon
const MouseIcon = styled.div`
  width: 24px;
  height: 36px;
  border: 2px solid ${(props) => props.theme.colors.ligthBlue};
  border-radius: 14px;
  position: relative;
  animation: ${bounce} 2s infinite;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    background-color: ${(props) => props.theme.colors.ligthBlue};
    border-radius: 50%;
  }
`;

// Component to render the bouncing mouse
const BouncingMouse = () => {
  return <MouseIcon />;
};

export default BouncingMouse;
