import { ThemeProvider } from "styled-components";
import { Layout } from "./common/Styles";
import About from "./components/About";
import Header from "./components/Header";
import ComingSoon from "./common/ComingSoon";
import Skills from "./components/Skills";

const theme = {
  colors: {
    light: "#F6F8FF",
    blue: "#4a7fe5",
    ligthBlue: "#A8B5EC",
    gradient:
      "linear-gradient(to right top, #4a7fe5, #7d9ced, #a7baf3, #cfd9fa, #f6f8ff)",
  },
};

const App = () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <Header />
      <About />
      <div style={{ marginTop: "5rem" }}>
        <Skills />
      </div>
      <ComingSoon />
    </Layout>
  </ThemeProvider>
);

export default App;
